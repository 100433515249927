import React from "react";
import {Link, graphql} from "gatsby";
import Img from "gatsby-image";
import colors from "../../lib/colors";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Ui from "../../components/Ui";
import Header from "../../components/Header";
import Avatar from "../../components/Avatar";
import Character from "../../components/Character";
import Media from "../../components/SSRSafeMedia";
import DefaultLayout from "../../components/DefaultLayout";
import NewsletterForm from "../../components/NewsletterForm";
import Strip from "../../components/ui/Strip";
import {Text} from "../../components/ui/Text";
import Col from "../../components/ui/Col";
import {themeBright} from "../../page-styles/landingpage.css";
import {XStrip} from "../../components/xui/Box";

const blogBreak = "@media(max-width: 700px)";

const PreviewContainer = styled(Link, {
  shouldForwardProp: prop => prop !== "isMostRecent",
})(({withImg}) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundColor: colors.white,
  borderRadius: 4,
  transitionProperty: "box-shadow",
  paddingBottom: "1.5rem",
  paddingTop: withImg ? 0 : "1.5rem",
  ":hover": {
    boxShadow: `0 0 0 1px ${colors.brand} inset`,
    "& h3": {
      borderLeftColor: colors.brand,
    },
  },
  [blogBreak]: {
    width: "auto",
    flex: "15rem 1 1",
  },
}));
const PreviewTitle = styled("h3")({
  fontSize: "1.2rem",
  padding: "0.5rem 1.5rem",
  marginBottom: "0.5rem",
  transitionProperty: "border-color",
  borderLeft: `4px solid ${colors.borderGray}`,
});
const PreviewContent = styled("div")(({isMostRecent}) => ({
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  fontSize: isMostRecent ? "1.05rem" : "0.9rem",
  lineHeight: 1.5,
  marginBottom: "1rem",
}));
const Author = styled("div")({color: colors.brand, fontWeight: "bold", marginBottom: "0.1rem"});
const PostDate = styled("div")({color: colors.midGray});
const PrevewImgBox = styled("div")({
  marginBottom: "1.5rem",
  position: "relative",
  height: 160,
  overflow: "hidden",
  borderRadius: "4px 4px 0 0",
});

const PreviewImg = styled(Img)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  objectPosition: "50% 50%",
});

const getUrl = post => post.fields.slug;

const Preview = ({post, isMostRecent}) => {
  const img = post.frontmatter.preview_image?.image.fixed;
  return (
    <PreviewContainer to={getUrl(post)} isMostRecent={isMostRecent} withImg={Boolean(img)}>
      {img ? (
        <PrevewImgBox>
          <PreviewImg fixed={img} alt="" style={{width: null, height: null}} />
        </PrevewImgBox>
      ) : null}
      {isMostRecent && (
        <Media query="(max-width: 559px)">
          {matches => (
            <Character
              src={require("../../imgs/characters/barde.svg")}
              isOnTop
              isOnLeft={matches}
            />
          )}
        </Media>
      )}
      {post.frontmatter.podcast && (
        <Col pb={1} css={{marginTop: "-0.5rem", paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>
          <Text preset="bold" color="purple500" size={1}>
            Game Production Podcast
          </Text>
          <Text preset="bold" color="purple400" size={0}>
            {post.frontmatter.podcast}
          </Text>
        </Col>
      )}
      <PreviewTitle isMostRecent={isMostRecent}>{post.frontmatter.title}</PreviewTitle>
      <PreviewContent
        isMostRecent={isMostRecent}
        dangerouslySetInnerHTML={{__html: post.frontmatter.description || post.excerpt}}
      />
      <Ui.Row
        css={{
          alignItems: "center",
          marginTop: "auto",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }}
      >
        <Avatar name={post.frontmatter.author} />
        <div css={css({marginLeft: "0.5rem", fontSize: "0.7rem"})}>
          <Author>{post.frontmatter.author}</Author>
          <PostDate>{post.frontmatter.date}</PostDate>
        </div>
      </Ui.Row>
    </PreviewContainer>
  );
};

const Container = styled("div")({
  backgroundColor: colors.brightBg,
  color: colors.dark,
});

const PostsContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(15rem, 1fr))",
  gridRowGap: "72px",
  gridColumnGap: "16px",
});

const BlogIndex = ({
  data: {
    allMarkdownRemark: {edges},
  },
  location,
}) => (
  <DefaultLayout
    title="Blog"
    footer="explain"
    location={location}
    theme={themeBright}
    description="Get the insider look about our journey towards improving video game planning for everybody."
  >
    <Container>
      <Header nonFloating />
      <Strip size="sm" py={7} bg="gray700" sp={5}>
        <Text as="h1" preset="bold" size={6} align="center" color="white">
          Blog
        </Text>
        <Col sp={4}>
          <Text color="gray200" size={5} align="center">
            Get the insider look about our journey towards improving video game planning for
            everybody.
          </Text>
          <Col sp={2} align="center">
            <NewsletterForm placeholder="Enter your email to subscribe" buttonLabel="Subscribe" />
            <Text color="gray400" size={1} align="center">
              No spam, you'll receive one to two updates a month.
            </Text>
          </Col>
        </Col>
      </Strip>
      <XStrip width="xxxl" sp={9}>
        <PostsContainer>
          {edges.map(({node: post}, i) => (
            <Preview key={post.fields.slug} isMostRecent={i === 0} post={post} />
          ))}
        </PostsContainer>
      </XStrip>
    </Container>
  </DefaultLayout>
);

export default BlogIndex;

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: {frontmatter: {layout: {eq: "Post"}}}
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMM, YYYY")
            title
            author
            description
            year: date(formatString: "YYYY")
            podcast
            no_year_in_url
            preview_image {
              image: childImageSharp {
                fixed(width: 340, height: 160) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
